import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';
import { header3Style } from '../h-tag/h-tag.styled';

export const MenuWrapper = styled.div<{ $hide?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xsmall};
  flex: 1;
  > *:first-child {
    flex: 1;
  }

  ${({ $hide }) =>
    $hide
    && css`
      display: none;
    `}
`;

export const MenuStyled = styled.div`
  .Accordion {
    .CollapseHeader,
    .notCollapsableItem {
      height: auto;
      margin-bottom: ${spacing.regular};
    }
  }
  .SlideInInside {
    .SlideInHeader {
      position: relative;
      border: none;
    }
    .SlideInBody {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 0;
      padding-bottom: ${spacing.regular};
    }
  }

  .Hide {
    display: none;
  }

  .SearchWrapper {
    padding: ${spacing.xsmall} ${spacing.small} ${spacing.medium} 0;
  }
  .CollapseHeader {
    transition: margin-bottom 0.3s;
  }
  .CollapseHeader.isOpen {
    margin-bottom: ${spacing.small};
  }
  .CollapseContent {
    padding-left: ${spacing.small};

    span {
      a {
        margin: 0;
        padding: 0;
      }
      &.Parent {
        display: block;
        padding-bottom: ${spacing.small};
        font-size: ${fontSizes.header4};
        line-height: ${lineHeights.header4};
        font-weight: ${fontWeights.fontMedium};
        letter-spacing: 0.01em;
        color: ${colors.black};
        &:hover {
          color: ${colors.blackHover};
        }
        &:not(:first-child) {
          padding-top: ${spacing.xsmall};
        }
      }

      &.Child {
        padding-left: ${spacing.small};
        padding-bottom: ${spacing.small};
        font-size: ${fontSizes.body16};
        line-height: ${lineHeights.body16};
        font-weight: ${fontWeights.fontRegular};
        color: ${colors.black};
        &:hover {
          color: ${colors.blackHover};
        }
      }
      &.Parent:last-child {
        margin-bottom: ${spacing.small};
      }

      &.LastChild {
        margin-bottom: ${spacing.small};
      }
    }
  }
  .CollapseContent.isOpen {
    max-height: 600px;
  }
  .CategoryLabel {
    font-size: ${fontSizes.label};
    line-height: ${lineHeights.label};
    color: ${colors.gray1};
  }
  .Result {
    &.show {
      top: 16px;
      padding-top: ${spacing.medium};
    }
  }
`;

export const PartnerLogin = styled.div<{ $hide?: boolean }>`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* padding-right: ${spacing.small}; */
  p {
    width: 100%;
  }
  ${({ $hide }) =>
    $hide
    && css`
      display: none;
    `}
`;
export const PartnerLoginLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  align-self: flex-end;
  /* width: auto; */
  text-decoration: none;
  font-size: ${fontSizes.body16};
  font-weight: ${fontWeights.fontRegular};
  color: ${colors.HGBlack};
  padding: ${spacing.small};
  &:hover {
    color: ${colors.gray1};
  }
  gap: ${spacing.xsmall};
`;

export const Label = styled(HTag).attrs({
  styleType: 'header3',
  type: 'span',
})``;

export const ItemLink = styled(Link)`
  ${header3Style};
  display: flex;
  min-height: 48px;
  align-items: center;
  padding-block: ${spacing.xsmall};
  padding-inline: ${spacing.small};
`;

export const SubItemLink = styled(Link)`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding-block: ${spacing.xsmall};
  padding-inline: ${spacing.medium};
`;
