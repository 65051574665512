import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './markdown.styled';

export interface MarkdownProps {
  children?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({ children, options }) => {
  const text = children?.replace(/&#(\d+);/g, (_search, found) => {
    const hexBase = 16;
    const unicodeLength = 4;
    const code = parseInt(found, 10)
      .toString(hexBase)
      .padStart(unicodeLength, '0');
    return JSON.parse(`"\\u${code}"`);
  });
  return <StyledMarkdown options={options}>{text ?? ''}</StyledMarkdown>;
};
