import dynamic from 'next/dynamic';

import {
  OfficeFragment,
  OfficesBlockFragment,
} from '@hultafors/hultaforsgroup/types';

import { ContactStoresStyled, Name } from './contact-stores.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);
const StoreItem = dynamic(() =>
  import('../store-item/store-item').then((mod) => mod.StoreItem),
);

interface ContactStoresProps {
  offices: OfficeFragment[];
  localWebLabel?: string;
  viewOnMapLabel?: string;
}

export const ContactStores: React.FC<
  ContactStoresProps | OfficesBlockFragment
> = ({ offices = [], localWebLabel, viewOnMapLabel }) => {
  const generateGoogleMapLinks = ({
    country,
    name,
    address,
    zipCode,
  }: OfficeFragment) => {
    return `https://www.google.com/maps/search/?api=1&query=${[
      'Hultafors Group',
      address,
      zipCode,
      country || name,
    ]
      .filter(Boolean)
      .join('+')}`;
  };

  const officeMapper = (office: OfficeFragment) => {
    const {
      id,
      name,
      address,
      zipCode,
      city,
      country,
      email,
      phoneNumber,
      websiteUrl,
    } = office;
    return (
      <AccordionItem key={`Office-${id}`} label={<Name>{name}</Name>}>
        <StoreItem
          address={address || ''}
          zipCode={zipCode || ''}
          city={city || ''}
          country={country || name || ''}
          mapLink={generateGoogleMapLinks(office)}
          view_on_map={viewOnMapLabel}
          email={email || ''}
          phone={phoneNumber || ''}
          websiteUrl={websiteUrl || ''}
          visit_web_site={localWebLabel}
        />
      </AccordionItem>
    );
  };
  if (!offices.length) {
    return null;
  }
  return (
    <ContactStoresStyled>
      <Accordion allowZeroExpanded>{offices.map(officeMapper)}</Accordion>
    </ContactStoresStyled>
  );
};
