import { Button } from '../button/button';
import { HTagStyleResponsive } from '../h-tag/h-tag.styled';

import {
  ButtonWrapper,
  MoreNewsWrapperWrapper,
  Title,
} from './more-news-wrapper.styled';

interface MoreNewsWrapperProps {
  bigTitle?: boolean;
  title?: string;
  CTAText?: string;
  CTAUrl?: string;
  children?: React.ReactNode;
}

export const MoreNewsWrapper: React.FC<MoreNewsWrapperProps> = ({
  bigTitle = false,
  title,
  children,
  CTAText,
  CTAUrl,
}) => {
  const bigHeader: HTagStyleResponsive = [
    { styleType: 'header1Mobile' },
    { breakpoint: 'mobileMax', styleType: 'header1' },
  ];

  return (
    <MoreNewsWrapperWrapper data-test="MoreNewsWrapper">
      <Title styleType={bigTitle ? bigHeader : 'header2'}>{title}</Title>
      {children}
      <ButtonWrapper>
        <Button to={CTAUrl}>{CTAText}</Button>
      </ButtonWrapper>
    </MoreNewsWrapperWrapper>
  );
};
