import { useState } from 'react';

import dynamic from 'next/dynamic';

import { createSubMenuLink, spacing } from '@hultafors/hultaforsgroup/helpers';
import { useGlobal } from '@hultafors/hultaforsgroup/hooks';
import {
  ItemRecord,
  MenuItemFragment,
  SubMenuItemFragment,
} from '@hultafors/hultaforsgroup/types';

import PartnerSvg from '../../svg/partner.svg';
import { SlideIn, SlideInToggler } from '../slide-in/slide-in';

import {
  ItemLink,
  Label,
  MenuStyled,
  MenuWrapper,
  PartnerLoginLink,
  SubItemLink,
} from './mobile-menu.styled';

const Accordion = dynamic(() =>
  import('../accordion/accordion').then((mod) => mod.Accordion),
);
const AccordionItem = dynamic(() =>
  import('../accordion-item/accordion-item').then((mod) => mod.AccordionItem),
);

interface MobileMenuProps {
  toggleMenu: SlideInToggler;
  headerText: string;
  menuItems: MenuItemFragment[];
  menuType?: string;
  partnerPortalUrl: string;
  partnerLoginLabel: string;
  isOpen: boolean;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
  menuItems = [],
  partnerPortalUrl = '/',
  partnerLoginLabel = '',
  headerText = 'Stuff',
  isOpen = false,
  toggleMenu,
  menuType,
  ...props
}) => {
  const { global } = useGlobal();
  const [focus, _setFocus] = useState(false);

  // const getNodeClass = (node, index, parentNodesLength) => {
  //   if (node.isParent) {
  //     return 'Parent';
  //   } else {
  //     if (parentNodesLength === index + 1) {
  //       return 'Child LastChild';
  //     }
  //   }
  //   return 'Child';
  // };

  const menuItemMapper = (item: MenuItemFragment, i: number) => {
    const key = `MobileMenuItem-${item.id}`;
    const onClick = () => toggleMenu();
    if (item.submenu) {
      // let parent = false;
      const subMenuMapper = (subItem: SubMenuItemFragment, j: number) => {
        // if (subItem.isParent) {
        //   parent = subItem;
        // }
        return (
          <SubItemLink
            key={`SubItemLink-${i}-${j}`}
            href={createSubMenuLink(subItem as ItemRecord)}
            onClick={onClick}
          >
            {subItem.title}
          </SubItemLink>
        );
      };
      const label = item.title ? <Label>{item.title}</Label> : '';
      return (
        <AccordionItem key={key} label={label} menu>
          {item.submenu.menuItems.map(subMenuMapper)}
        </AccordionItem>
      );
    } else {
      return (
        <ItemLink key={key} href={item.url || ''} onClick={onClick}>
          {item.title}
        </ItemLink>
      );
    }
  };

  return (
    <MenuStyled data-test="MobileMenu">
      {menuItems.length > 0 && (
        <SlideIn
          toggle={toggleMenu}
          isOpen={isOpen}
          headerText={global.menu || 'Menu'}
          isMenu
          padContent
          id="mobileMenu"
        >
          <MenuWrapper $hide={focus}>
            <Accordion allowZeroExpanded>
              {menuItems.map(menuItemMapper)}
            </Accordion>

            <PartnerLoginLink
              href={partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {partnerLoginLabel}
              <PartnerSvg width={spacing.small} height={spacing.small} />
            </PartnerLoginLink>
          </MenuWrapper>
        </SlideIn>
      )}
    </MenuStyled>
  );
};
