export const colors = {
  HGBlack: '#212126',
  HGWhite: '#FFFFFF',
  HGBlue: '#2B79AB',
  white: '#FFFFFF',
  HGGray1: '#313138',
  HGGray2: '#CCCCCC',
  HGGray3: '#F7F7F7',
  HGGray4: '#E6E6E6',
  HGGray5: '#616161',
  gray1: '#90909B',
  gray2: '#B3B4BC',
  gray3: '#DEDEDE',
  gray4: '#E6E6E6',
  gray5: '#F8F8FC',
  gray6: '#F7F7F7',
  black: '#111111',
  overlayTransparent: 'rgba(35, 33, 32, 0.3)',
  textShadowBlack: 'rgba(33, 33, 38, .8)',
  textShadowWhite: 'rgba(255, 255, 255, .8)',
  blackHover: '#2D2D2D',
  boxshadowTransparent: 'rgba(0, 0, 0, 0.1)',
  // Legacy
  yellow: 'rgba(242, 201, 76, 1)',
  hellNo: '#FF1E1E',
  hellYeah: '#67FC6D',
} as const;
