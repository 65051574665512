import { forwardRef } from 'react';

import { FieldWrapper } from '../field-wrapper/field-wrapper';
import { Label } from '../label/label';

import { StyledInput } from './input.styled';

interface InputProps {
  id: string;
  inline?: boolean;
  label?: string;
  ariaLabel?: string;
  type?: string;
  wrapperProps: any;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label = '', id, inline = false, type = 'text', ariaLabel = '' }, ref) => {
    return (
      <FieldWrapper inline={inline}>
        {label && <Label htmlFor={id}>{label}</Label>}

        <StyledInput id={id} type={type} aria-label={ariaLabel} ref={ref} />
      </FieldWrapper>
    );
  },
);
