import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerWideStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';

export const MoreNewsWrapperWrapper = styled.div`
  ${contentContainerWideStyle}
  padding: ${spacing.large} 0 ${spacing.XL};
  border-top: 1px solid ${colors.HGGray4};
`;

export const Title = styled(HTag).attrs({ type: 'h2' })`
  padding-bottom: ${spacing.xsmall};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${spacing.medium};
`;
