import {
  AlertPlacement,
  AlertSize,
  AlertStatus,
  StyledAlert,
} from './alert.styled';

interface AlertProps {
  size?: AlertSize;
  status?: AlertStatus;
  position: React.CSSProperties['position'];
  placement?: AlertPlacement;
}

export const Alert: React.FC<AlertProps> = ({
  size = 'medium',
  status = 'info',
  position = 'relative',
  placement = 'bottom',
}) => (
  <StyledAlert
    $size={size}
    $status={status}
    $position={position}
    $placement={placement}
  />
);
