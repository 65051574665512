import { ContentContainer } from '../content-container/content-container';
import { Paragraph } from '../paragraph/paragraph';

import { NewsLabelWrapper } from './news-label.styled';

interface NewsLabelProps {
  label?: string;
}

export const NewsLabel: React.FC<NewsLabelProps> = ({ label }) => (
  <NewsLabelWrapper>
    <ContentContainer>
      <Paragraph styleType="label">{label}</Paragraph>
    </ContentContainer>
  </NewsLabelWrapper>
);
