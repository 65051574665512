import Image from 'next/image';
import styled, { css, CSSProperties } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerWideStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';

interface StyledBigTextBlockProps {
  $black?: boolean;
  $aspectRatio?: CSSProperties['aspectRatio'];
  $small?: boolean;
}

export const StyledBigTextBlock = styled.div<StyledBigTextBlockProps>`
  position: relative;
  background-color: ${({ $black }) =>
    $black ? colors.HGBlack : colors.HGWhite};
  display: flex;
  align-items: center;
  aspect-ratio: 3 / 5;

  ${({ $small }) =>
    $small
    && css`
      @media screen and (min-width: ${breakpoints.bigMobile}) {
        aspect-ratio: 1 / 1;
      }
    `}

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 16 / 9;
  }

  ${({ $small }) =>
    $small
    && css`
      width: calc(100vw - 48px);
      margin-inline: auto;
      padding: 1rem;

      @media screen and (min-width: ${breakpoints.mobileMax}) {
        width: 62.8vw;
        max-width: 660px;
      }
    `}
`;

export const ImageMobile = styled(Image)`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const ImageTablet = styled(Image)`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;
export const ImageDesktop = styled(Image)`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`;

export const StyledImage = styled(Image)`
  object-fit: cover;
`;

export const Inner = styled.div`
  ${contentContainerWideStyle};
  z-index: 1;

  p:last-of-type {
    margin-top: 1rem;
  }

  a {
    font-size: ${fontSizes.body16};
    text-decoration: underline;
    color: white;
  }
`;

export const Title = styled(HTag).attrs({
  type: 'h2',
  styleType: [
    { styleType: 'header2Bold' },
    { breakpoint: 'desktop', styleType: 'header1Bold' },
  ],
})`
  ${({ whiteText }) =>
    whiteText
    && css`
      text-shadow: 0 0 0.25ch ${colors.textShadowBlack};
    `}
`;

export const Description = styled(HTag).attrs({
  type: 'p',
  styleType: [
    { styleType: 'header2' },
    { breakpoint: 'desktop', styleType: 'header1' },
  ],
})`
  ${({ whiteText }) =>
    whiteText
    && css`
      text-shadow: 0 0 0.25ch ${colors.textShadowBlack};
    `}
`;
