import { useEffect } from 'react';

import Link, { LinkProps } from 'next/link';

import { StyledTextButton } from './text-button.styled';

interface IconProps {
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}

const Icon: React.FC<IconProps> = ({ icon, style }) => {
  if (!icon) {
    return null;
  }
  if (typeof icon === 'string') {
    return <img src={icon} alt="icon" style={style} />;
  }
  return icon;
};

interface TextButtonProps {
  to?: LinkProps['href'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  arrow?: boolean;
  variant?: 'black' | 'white';
  icon?: React.ReactNode;
  iconSize?: number;
  loading?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  arrow = false,
  loading = false,
  variant = 'black',
  to,
  icon = null,
  iconSize = 24,
  onClick,
  children,
}) => {
  useEffect(() => {
    if (arrow && icon) {
      console.error("Can't have both arrow and icon in TextButton component");
    }
    if (to && onClick) {
      console.error(
        "Can't have both onClick and to prop in TextButton component",
      );
    }
    // remove warning as this is just an error check to see if button is instansiated with confronting props
  }, []);

  if (!to) {
    return (
      <StyledTextButton
        $variant={variant}
        $arrow={arrow}
        $icon={icon}
        $loading={loading}
        onClick={onClick}
        $iconSize={iconSize}
      >
        {children}
        {icon && !arrow && <Icon icon={icon} />}
        {arrow && !icon && <Icon icon="/assets/gfx/arrow-right.svg" />}
      </StyledTextButton>
    );
  }
  return (
    <StyledTextButton
      // remove eslint, since extracting props to NOT send them is arguably still valid js
      // eslint-disable-next-line no-unused-vars
      as={({ className }: { className?: string }) => {
        const extraProps
          = typeof to === 'string'
          && (to?.startsWith('http://') || to?.startsWith('https://'))
            ? { rel: 'noreferrer noopener', target: '_blank' }
            : {};
        return (
          <Link href={to || ''} className={className} {...extraProps}>
            {children}
            {icon && !arrow && (
              <Icon
                icon={icon}
                style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
              />
            )}
            {arrow && !icon && (
              <Icon
                icon="/assets/gfx/arrow-right.svg"
                style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
              />
            )}
          </Link>
        );
      }}
      $variant={variant}
      $loading={loading}
      $icon={icon}
      $iconSize={iconSize}
      $arrow={arrow}
    />
  );
};
