import { StyledFieldWrapper } from './field-wrapper.styled';

export interface FieldWrapperProps {
  inline?: boolean;
  children?: React.ReactNode;
}

export const FieldWrapper: React.FC<FieldWrapperProps> = ({
  inline = false,
  children,
}) => <StyledFieldWrapper $inline={inline}>{children}</StyledFieldWrapper>;
