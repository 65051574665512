import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { colors, fontSizes, spacing } from '@hultafors/hultaforsgroup/helpers';

import { header3Style } from '../h-tag/h-tag.styled';

export const StyledMarkdown = styled(Markdown)`
  ${header3Style};

  display: block;
  flex-direction: column;
  font-size: ${fontSizes.body16};

  > * + * {
    margin-block-start: ${spacing.small};
  }

  p {
    font-size: ${fontSizes.body16};
  }

  a {
    text-decoration: underline;

    &:hover {
      color: ${colors.gray1};
    }
  }

  margin-bottom: ${spacing.medium};
`;
