import { useEffect, useState } from 'react';

import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

const TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY = 'timed_dialog_renew_date';
const RENEW_INTERVAL = 6 * 30 * 24 * 3600 * 1000; // 6 MONTHS

interface TimedDialogProps {
  delay: number;
  formUrl: string;
  image: any;
}

export const TimedDialog: React.FC<TimedDialogProps> = ({
  delay,
  formUrl,
  image,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    const renewDate = localStorage.getItem(
      TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY,
    );

    if (!renewDate || Date.now() > Number(renewDate)) {
      setTimeout(() => {
        const now = Date.now();

        setIsDialogOpen(true);
        localStorage.setItem(
          TIMED_DIALOG_RENEW_DATE_LOCAL_STORAGE_KEY,
          `${now + RENEW_INTERVAL}`,
        );
      }, delay * 1000);
    }
  }, []);

  return isDialogOpen
    ? (
      <SubscriptionDialog
        formUrl={formUrl}
        image={image}
        onHandleClose={closeDialog}
      />
      )
    : null;
};
