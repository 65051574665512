import { StyledLabel } from './label.styled';

interface LabelProps {
  htmlFor: string;
  children?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = ({ htmlFor, children }) => (
  <StyledLabel htmlFor={htmlFor}>{children}</StyledLabel>
);
