import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

export const SectionStyled = styled.section`
  padding: ${spacing.medium} ${spacing.small};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.medium} ${spacing.medium};
  }

  &.SmallMarginTop {
    padding-top: ${spacing.small};
  }

  &.LargeMarginTop {
    padding-top: ${spacing.large};
  }

  &.SmallMarginBottom {
    padding-bottom: ${spacing.small};
  }

  &.MediumMarginBottom {
    padding-bottom: ${spacing.regular};
  }

  &.LargeMarginBottom {
    padding-bottom: ${spacing.large};
  }

  &.MegaMarginBottom {
    padding-bottom: ${spacing.XL};
  }

  &.FullWidth {
    padding-left: 0;
    padding-right: 0;
  }

  &.BorderTop {
    border-top: 1px solid ${colors.gray4};
  }

  &.BorderBottom {
    border-bottom: 1px solid ${colors.gray4};
  }

  &.NoMarginBottom {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &.NoMarginTop {
    margin-top: 0;
    padding-top: 0;
  }

  &.NoMargin {
    margin: 0;
    padding: 0;
  }

  &.MaxWidth {
    max-width: ${breakpoints.maxPageWidth};

    @media screen and (min-width: ${breakpoints.maxPageWidth}) {
      width: 100%;
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
