import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { routes } from '@hultafors/hultaforsgroup/helpers';
import { BrandFragment } from '@hultafors/hultaforsgroup/types';

import {
  BrandLink,
  BrandName,
  BrandsGrid,
  ImageWrapper,
  IntroText,
  OurBrandsBlockWrapper,
  Title,
} from './our-brands-block.styled';

interface OurBrandsBlockProps {
  title?: string | null | undefined;
  introText?: string | null | undefined;
  brands?: BrandFragment[];
}

export const OurBrandsBlock: React.FC<OurBrandsBlockProps> = ({
  title,
  brands = [],
  introText,
}) => {
  if (!title && !brands.length && !introText) {
    return null;
  }
  const brandMapper = ({ brandName, slug, image }: BrandFragment) => (
    <BrandLink
      key={`key-${brandName}`}
      href={{ pathname: `${routes.brands}/${slug}` }}
    >
      <ImageWrapper>
        {image?.responsiveImage?.src && (
          <Image
            src={image.responsiveImage.src || ''}
            blurDataURL={image.responsiveImage?.base64 || ''}
            placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
            alt={image.alt || brandName || ''}
            sizes={`50vw, (min-width: ${breakpoints.tablet}) 25vw, (min-width: ${breakpoints.desktop}) 148px`}
            fill
          />
        )}
      </ImageWrapper>
      <BrandName>{brandName}</BrandName>
    </BrandLink>
  );

  return (
    <OurBrandsBlockWrapper id="our-brands" data-test="OurBrandsBlock">
      <Title>{title}</Title>
      {introText && <IntroText>{introText}</IntroText>}
      <BrandsGrid>{brands.map(brandMapper)}</BrandsGrid>
    </OurBrandsBlockWrapper>
  );
};
