import {
  LinkField,
  LinkFieldTypeGuards,
} from '@hultafors/hultaforsgroup/types';

export const createLink = (
  route: string,
  item: LinkField,
  parent?: LinkField,
) => {
  const { isItemRecord } = LinkFieldTypeGuards;
  const slug = isItemRecord(item) ? item.pageLink?.slug : item.url;
  if (parent) {
    const parentSlug = isItemRecord(parent)
      ? parent.pageLink?.slug
      : parent.url;
    return `/${route}/${parentSlug}/${slug}`;
  } else {
    return `/${route}/${slug}`;
  }
};
