import { StyledIconButtonWrapper } from './icon-button-wrapper.styled';

interface IconButtonWrapperProps {
  children?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  name?: string;
}

export const IconButtonWrapper: React.FC<IconButtonWrapperProps> = ({
  onClick,
  className,
  children,
  name,
}) => (
  <StyledIconButtonWrapper
    name={name}
    aria-label={name}
    className={className}
    onClick={onClick}
  >
    {children}
  </StyledIconButtonWrapper>
);
