import styled, { css } from 'styled-components';

import { Breakpoint, breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultaforsgroup/helpers';

export type HTagStyleType =
  | 'header1Bold'
  | 'header1'
  | 'header1Medium'
  | 'header1Mobile'
  | 'header1MobileMedium'
  | 'header2Medium'
  | 'header2Bold'
  | 'header2'
  | 'header3'
  | 'header4'
  | 'header4Medium';

interface HTagWHiteTextValue {
  breakpoint?: Breakpoint;
  whiteText?: boolean;
}

export type HTagWhiteTextProp = boolean | HTagWHiteTextValue[];

interface HTagResponsiveStyleType {
  breakpoint?: Breakpoint;
  styleType: HTagStyleType;
}

export type HTagStyleResponsive = HTagResponsiveStyleType[];

export type HTagStyleTypeProp = HTagStyleType | HTagStyleResponsive;

interface HTagStyledProps {
  $whiteText?: HTagWhiteTextProp;
  $center?: boolean;
  $styleType: HTagStyleTypeProp;
}

export const header1BoldStyle = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontBold};
`;
export const header1MediumStyle = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.01em;
`;
export const header1Style = css`
  font-size: ${fontSizes.header1};
  line-height: ${lineHeights.header1};
  font-weight: ${fontWeights.fontRegular};
  letter-spacing: 0.01em;
`;
export const header1MobileStyle = css`
  font-size: ${fontSizes.header1Mobile};
  line-height: ${lineHeights.header1Mobile};
  font-weight: ${fontWeights.fontRegular};
  letter-spacing: 0.01em;
`;
export const header1MobileMediumStyle = css`
  font-size: ${fontSizes.header1Mobile};
  line-height: ${lineHeights.header1Mobile};
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.01em;
`;
export const header2MediumStyle = css`
  font-size: ${fontSizes.header2Medium};
  line-height: ${lineHeights.header2Medium};
  font-weight: ${fontWeights.fontMedium};
`;
export const header2BoldStyle = css`
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontBold};
`;
export const header2Style = css`
  font-size: ${fontSizes.header2};
  line-height: ${lineHeights.header2};
  font-weight: ${fontWeights.fontRegular};
`;
export const header3Style = css`
  font-size: ${fontSizes.header3};
  line-height: ${lineHeights.header3};
  font-weight: ${fontWeights.fontRegular};
`;
export const header4Style = css`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontRegular};
`;
export const header4MediumStyle = css`
  font-size: ${fontSizes.header4};
  line-height: ${lineHeights.header4};
  font-weight: ${fontWeights.fontMedium};
`;

const fontStyle = (styleType?: HTagStyleType) => {
  switch (styleType) {
    case 'header1Bold':
      return header1BoldStyle;
    case 'header1Medium':
      return header1MediumStyle;
    case 'header1':
      return header1Style;
    case 'header1Mobile':
      return header1MobileStyle;
    case 'header1MobileMedium':
      return header1MobileMediumStyle;
    case 'header2Medium':
      return header2MediumStyle;
    case 'header2Bold':
      return header2BoldStyle;
    case 'header2':
      return header2Style;
    case 'header3':
      return header3Style;
    case 'header4':
      return header4Style;
    case 'header4Medium':
      return header4MediumStyle;
    default:
      return header1Style;
  }
};

const whiteTextMapper = ({ breakpoint, whiteText }: HTagWHiteTextValue) => {
  if (breakpoint) {
    return css`
      @media screen and (min-width: ${breakpoints[breakpoint]}) {
        color: ${whiteText ? colors.HGWhite : colors.HGBlack};
      }
    `;
  }
  return css`
    color: ${whiteText ? colors.HGWhite : colors.HGBlack};
  `;
};

const handleWhiteText = ({ $whiteText }: HTagStyledProps) => {
  if (Array.isArray($whiteText)) {
    return $whiteText.map(whiteTextMapper);
  }
  return css`
    color: ${$whiteText ? colors.HGWhite : colors.HGBlack};
  `;
};

const handleStyleTypeMapper = ({
  breakpoint,
  styleType,
}: HTagResponsiveStyleType) => {
  if (breakpoint) {
    return css`
      @media screen and (min-width: ${breakpoints[breakpoint]}) {
        ${fontStyle(styleType)}
      }
    `;
  }
  return fontStyle(styleType);
};

const handleStyleType = ({ $styleType }: HTagStyledProps) => {
  if (Array.isArray($styleType)) {
    return $styleType.map(handleStyleTypeMapper);
  }
  return fontStyle($styleType);
};

export const StyledHTag = styled.h1<HTagStyledProps>`
  text-align: ${({ $center }) => ($center ? 'center' : 'initial')};
  ${handleWhiteText}
  ${handleStyleType}
`;
