import { createSubMenuLink } from '@hultafors/hultaforsgroup/helpers';
import {
  ItemRecord,
  SubMenuItemFragment,
} from '@hultafors/hultaforsgroup/types';

import { SlideIn, SlideInToggler } from '../slide-in/slide-in';

import {
  DesktopMenuContent,
  DesktopMenuLink,
  DesktopMenuList,
} from './desktop-menu.styled';

interface DesktopMenuProps {
  toggleDesktopMenu: SlideInToggler;
  menuItems: SubMenuItemFragment[];
  partnerPortalUrl?: string;
  menuType?: string;
  openType?: string;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  toggleDesktopMenu,
  menuItems,
  menuType,
  openType,
}) => {
  const onClick = () => toggleDesktopMenu();

  const menuItemsMapper = (item: SubMenuItemFragment, i: number) => (
    <DesktopMenuLink
      key={`Product-Menu-${i}`}
      href={createSubMenuLink(item as ItemRecord)}
      onClick={onClick}
    >
      {item.title}
    </DesktopMenuLink>
  );

  return (
    <SlideIn
      id="desktopMenu"
      toggle={toggleDesktopMenu}
      noHeader
      isOpen={openType === menuType}
      fromTop
      isMenu
    >
      <DesktopMenuContent>
        <DesktopMenuList>{menuItems.map(menuItemsMapper)}</DesktopMenuList>
      </DesktopMenuContent>
    </SlideIn>
  );
};
