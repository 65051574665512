import {
  JobOpportunitiesPageListingFragment,
  NewsPageListingFragment,
} from '@hultafors/hultaforsgroup/types';

import { ArticleItem } from '../article-item/article-item';

import { StyledArticleList } from './article-list.styled';

type ListItem = JobOpportunitiesPageListingFragment | NewsPageListingFragment;
interface ArticleListProps {
  newsList: ListItem[];
  isNewsArticle?: boolean;
  isJobAd?: boolean;
}

export const ArticleList: React.FC<ArticleListProps> = ({
  newsList,
  isNewsArticle,
  isJobAd,
}) => (
  <StyledArticleList data-test="NewsArticleList">
    {newsList?.map((item, index) => {
      return (
        <ArticleItem
          key={`key${index}`}
          publishDate={item.publishDate}
          slug={item.slug || ''}
          title={item.title || ''}
          category={item.category || ''}
          country={item.country || ''}
          // department={item.department}
          isNewsArticle={isNewsArticle}
          isJobAd={isJobAd}
        />
      );
    })}
  </StyledArticleList>
);
