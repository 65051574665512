import { ContentContainerStyled } from './content-container.styled';

interface ContentContainerProps {
  children?: React.ReactNode;
  wide?: boolean;
  style?: React.CSSProperties;
}
export const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  wide,
  style,
}) => {
  return (
    <ContentContainerStyled $wide={wide} style={style}>
      {children}
    </ContentContainerStyled>
  );
};
