import { TextButton } from '../text-button/text-button';

import { DialogContent, DialogHeader, DialogWrapper } from './dialog.styled';

export interface DialogProps {
  children?: React.ReactNode;
  closeButtonLabel: React.ReactNode;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  isFullscreen?: boolean;
  top?: React.CSSProperties['top'];
  right?: React.CSSProperties['right'];
  bottom?: React.CSSProperties['bottom'];
  left?: React.CSSProperties['left'];
  height?: React.CSSProperties['height'];
  width?: React.CSSProperties['width'];
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  closeButtonLabel = 'Close',
  onClose,
  isFullscreen = true,
  height = '100%',
  width = '100%',
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
}) => {
  return (
    <DialogWrapper
      $top={top}
      $right={right}
      $bottom={bottom}
      $left={left}
      $height={height}
      $width={width}
    >
      <DialogHeader $isFullscreen={isFullscreen}>
        <TextButton data-test="DialogClose" onClick={onClose}>
          {closeButtonLabel}
        </TextButton>
      </DialogHeader>
      <DialogContent>{children}</DialogContent>
    </DialogWrapper>
  );
};
