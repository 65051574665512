import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const SimpleTitleAndText = styled.div<{ $noMarginBottom?: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: ${spacing.medium};
  margin-bottom: ${({ $noMarginBottom }) =>
    $noMarginBottom ? 0 : spacing.medium};
`;

export const Title = styled(HTag)`
  font-weight: 500;
`;

export const TopLabel = styled(Paragraph).attrs({ styleType: 'label' })`
  margin-bottom: ${spacing.xsmall};
`;

export const Description = styled(HTag).attrs({
  type: 'p',
  styleType: 'header3',
})`
  margin-block-start: ${spacing.medium};
`;
