import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const StyledTextarea = styled.textarea`
  appearance: none;
  flex: 1;
  width: 100%;
  min-height: 150px;
  box-sizing: border-box;
  padding: ${spacing.xsmall};
  font-size: ${fontSizes.body14};
  line-height: ${lineHeights.body14};
  border: 0;
  border-radius: 5px;
  border-bottom: 1px solid ${colors.gray3};
  background-color: ${colors.gray5};

  &:focus {
    outline: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }
`;
