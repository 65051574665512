import { useEffect, useRef, useState } from 'react';

import { useClickAway, useKey } from 'react-use';

import { useLockBodyScroll } from '@hultafors/shared/hooks';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import ClearSvg from '../../svg/clear.svg';
import CloseSvg from '../../svg/close.svg';

import {
  Clear,
  ClearLabel,
  Close,
  CloseButton,
  HeaderIcon,
  HeaderText,
  HeaderTextInner,
  SlideInBackground,
  SlideInBody,
  SlideInContainer,
  SlideInExit,
  SlideInHeader,
  SlideInInside,
  slideInTime,
} from './slide-in.styled';

export type SlideInToggler<T = string> = (key?: T | null) => void;

interface SlideInProps {
  children?: React.ReactNode;
  backText?: string;
  isOpen: boolean;
  toggle: SlideInToggler;
  clearAll?(): void;
  headerText?: string;
  headerIcon?: string;
  clearText?: string;
  currentStepNumber?: number | null;
  isMenu?: boolean;
  isCart?: boolean;
  partnerPortalUrl?: string;
  partnerLabel?: string;
  shopLinkUrl?: string;
  shopLinkLabel?: string;
  changeLanguageText?: string;
  itemLength?: number;
  id: string;
  className?: string;
  onShowStart?(): void;
  onShowComplete?(): void;
  onHideStart?(): void;
  onHideComplete?(): void;
  noHeader?: boolean;

  fromLeft?: boolean;
  small?: boolean;
  padContent?: boolean;
  fromTop?: boolean;
}

export const SlideIn: React.FC<SlideInProps> = ({
  children,
  isOpen,
  isMenu,
  isCart,
  fromLeft = true,
  fromTop,
  small,
  padContent,
  clearText = '',
  headerText = '',
  headerIcon = '',
  itemLength,
  clearAll,
  toggle,
  id = '',
  onShowStart,
  onShowComplete,
  onHideStart,
  onHideComplete,
}) => {
  const { global } = useGlobal();
  const ref = useRef<HTMLDivElement>(null);
  const innerRef = useRef(null);
  const [animating, setAnimating] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [animationTimer, setAnimationTimer] = useState<NodeJS.Timeout>();
  useLockBodyScroll(active);

  const close = () => {
    if (isOpen) {
      toggle('');
    }
  };

  useKey('Escape', () => {
    if (isOpen) {
      close();
    }
  });

  // TODO: Figure out click away
  useClickAway(innerRef, (event) => {
    const target = event.target as HTMLButtonElement;
    if (
      isOpen
      && (!target?.dataset?.['slideinIgnore']
      || (id && target?.dataset?.['slideinIgnore'] !== id))
    ) {
      close();
    }
  });

  useEffect(() => {
    if (initialized) {
      setAnimating(true);
      if (isOpen) {
        setActive(true);
        if (onShowStart) {
          onShowStart();
        }
        setAnimationTimer(
          setTimeout(() => {
            setAnimating(false);
            if (onShowComplete) {
              onShowComplete();
            }
          }, slideInTime),
        );
      } else {
        if (onHideStart) {
          onHideStart();
        }
        setAnimationTimer(
          setTimeout(() => {
            if (onHideComplete) {
              onHideComplete();
            }
            setActive(false);
            setAnimating(false);
          }, slideInTime),
        );
      }
    } else {
      setInitialized(true);
    }

    return () => {
      clearTimeout(animationTimer);
    };
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setActive(false);
    };
  }, []);

  return (
    <SlideInContainer
      $active={active}
      $isOpen={isOpen}
      $fromTop={fromTop}
      ref={ref}
      aria-hidden={!isOpen}
    >
      <SlideInBackground $active={active} $fromTop={fromTop} $isOpen={isOpen} />
      <SlideInInside
        $active={active}
        $isOpen={isOpen}
        $fromLeft={fromLeft}
        $fromTop={fromTop}
        $small={small}
        ref={innerRef}
        $animating={animating}
      >
        <SlideInHeader $fromTop={fromTop} $small={isMenu} $isMenu={isMenu}>
          <HeaderText $isMenu={isMenu} $fromTop={fromTop}>
            {isMenu
              ? (
                <Close
                  $isMenu={isMenu}
                  onClick={close}
                  aria-label={global?.closeLabel || 'Close'}
                >
                  <CloseSvg
                    focusable="false"
                    aria-hidden="true"
                    width={isMenu ? 16 : 24}
                    height={isMenu ? 16 : 24}
                  />
                </Close>
                )
              : (
                <>
                  {headerIcon && (
                    <HeaderIcon
                      src={headerIcon}
                      aria-hidden={true}
                      width="24px"
                      height="24px"
                      loading="lazy"
                      sizes="24px"
                    />
                  )}
                  <HeaderTextInner>{headerText}</HeaderTextInner>
                </>
                )}
          </HeaderText>
          <SlideInExit>
            {clearAll && clearText && !!itemLength && (
              <Clear onClick={clearAll} key="ClearButton">
                <ClearLabel>{clearText}</ClearLabel>
                <ClearSvg
                  focusable="false"
                  aria-hidden="true"
                  width={16}
                  height={16}
                />
              </Clear>
            )}
            {!isMenu && (
              <CloseButton onClick={close} aria-label={global.closeLabel || ''}>
                <CloseSvg focusable="false" aria-hidden={true} />
              </CloseButton>
            )}
          </SlideInExit>
        </SlideInHeader>
        <SlideInBody
          $isCart={isCart}
          $fromTop={fromTop}
          $padContent={!isMenu && padContent}
        >
          {children}
        </SlideInBody>
      </SlideInInside>
    </SlideInContainer>
  );
};
