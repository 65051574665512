import Link from 'next/link';

import { StatementBlockFragment } from '@hultafors/hultaforsgroup/types';

import {
  Description,
  ImageDesktop,
  ImageMobile,
  ImageTablet,
  Inner,
  StyledBigTextBlock,
  Title,
} from './big-text-block.styled';

interface BigTextBlockProps {
  title: string;
  description: string;
  imageMobile?: StatementBlockFragment['imageMobile'];
  imageTablet?: StatementBlockFragment['imageTablet'];
  imageDesktop?: StatementBlockFragment['imageDesktop'];
  linkText?: string;
  linkUrl?: string;
  black?: boolean;
  aspectRatio?: React.CSSProperties['aspectRatio'];
  small?: boolean;
}

export const BigTextBlock: React.FC<BigTextBlockProps> = ({
  title,
  description,
  black = false,
  imageMobile,
  imageTablet,
  imageDesktop,
  small,
  linkText,
  linkUrl,
}) => {
  return (
    <StyledBigTextBlock $black={black || !!imageMobile} $small={!!small}>
      {imageMobile?.responsiveImage?.src && (
        <ImageMobile
          src={imageMobile.responsiveImage.src}
          blurDataURL={imageMobile.responsiveImage?.base64 || ''}
          placeholder={imageMobile.responsiveImage?.base64 ? 'blur' : 'empty'}
          aria-hidden={true}
          alt={title || ''}
          sizes="100vw"
          fill
        />
      )}
      {imageTablet?.responsiveImage?.src && (
        <ImageTablet
          src={imageTablet.responsiveImage.src}
          blurDataURL={imageTablet.responsiveImage?.base64 || ''}
          placeholder={imageTablet.responsiveImage?.base64 ? 'blur' : 'empty'}
          aria-hidden={true}
          alt={title || ''}
          sizes="100vw"
          fill
        />
      )}
      {imageDesktop?.responsiveImage?.src && (
        <ImageDesktop
          src={imageDesktop.responsiveImage.src}
          blurDataURL={imageDesktop.responsiveImage?.base64 || ''}
          placeholder={imageDesktop.responsiveImage?.base64 ? 'blur' : 'empty'}
          aria-hidden={true}
          alt={title || ''}
          sizes="100vw"
          fill
        />
      )}
      <Inner>
        {title && <Title whiteText={!!(black || imageMobile)}>{title}</Title>}
        {description && (
          <Description whiteText={!!(black || imageMobile)}>
            {description}
          </Description>
        )}

        {linkText && linkUrl && (
          <Description whiteText={!!(black || imageMobile)}>
            {linkText}
            {' '}
            {linkUrl && <Link href={`${linkUrl}`}>{linkUrl}</Link>}
          </Description>
        )}
      </Inner>
    </StyledBigTextBlock>
  );
};
