import { DialogProps } from '../dialog/dialog';
import { Image } from '../image/image';

import {
  BackDrop,
  DialogContent,
  ImageWrapper,
  StyledDialog,
  StyledIFrameContainer,
} from './subscription-dialog.styled';

interface SubscriptionDialogProps {
  formUrl?: string;
  image: { url?: string; title?: string };
  onHandleClose: DialogProps['onClose'];
}

export const SubscriptionDialog: React.FC<SubscriptionDialogProps> = ({
  formUrl = '',
  image = {},
  onHandleClose,
}) => {
  const { url: imageUrl = '', title: imageTitle = '' } = image;

  return (
    <>
      <BackDrop />
      <StyledDialog
        isFullscreen={false}
        onClose={onHandleClose}
        closeButtonLabel={
          <Image src="/assets/gfx/close.svg" alt="Close Icon" />
        }
      >
        <DialogContent>
          {imageUrl && (
            <ImageWrapper>
              <Image src={`${imageUrl}?width=800`} alt={imageTitle} />
            </ImageWrapper>
          )}
          <StyledIFrameContainer title="dialog-form" src={formUrl} />
        </DialogContent>
      </StyledDialog>
    </>
  );
};
