import { Image } from '../image/image';

import { StyledPicture } from './picture.styled';

interface PictureProps {
  caption: string;
  children?: React.ReactNode;
  pictureProps: any;
  sizes?: string;
  sources: {
    alt?: string;
    src?: string;
    srcSet?: string;
    type: string; // ['image/webp', 'image/jpeg', 'image/png', 'image/gif'] ...
    sizes?: string; // Allow to override the "sizes" prop for one specific source if needed
  }[];
}

export const Picture: React.FC<PictureProps> = ({
  caption,
  children,
  pictureProps = {},
  sizes = 'auto',
  sources = [],
}) => {
  return (
    <StyledPicture {...pictureProps}>
      {sources.map(({ sizes: sourceSizes, ...source }) => (
        <source
          key={`source-${caption}-${source.type}`}
          sizes={sourceSizes || sizes}
          {...source}
        />
      ))}
      <Image alt={caption} sizes={sizes} />
      {children}
    </StyledPicture>
  );
};
