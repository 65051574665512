import { useState } from 'react';

import { Button } from '../button/button';
import { SubscriptionDialog } from '../subscription-dialog/subscription-dialog';

import { StyledParagraph } from './subscribe-area.styled';

interface SubscribeAreaProps {
  subscribeInfoText?: string;
  subscribeButtonText?: string;
  userEmailFormUrl?: string;
  userEmailFormImage: any;
}

export const SubscribeArea: React.FC<SubscribeAreaProps> = ({
  subscribeInfoText,
  subscribeButtonText,
  userEmailFormUrl = '',
  userEmailFormImage = {},
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <>
      <StyledParagraph>{subscribeInfoText}</StyledParagraph>
      <Button onClick={toggleDialog}>{subscribeButtonText}</Button>

      {isDialogOpen && (
        <SubscriptionDialog
          formUrl={userEmailFormUrl}
          image={userEmailFormImage}
          onHandleClose={toggleDialog}
        />
      )}
    </>
  );
};
