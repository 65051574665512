import { BulletListFragment } from '@hultafors/hultaforsgroup/types';

import { ContentContainer } from '../content-container/content-container';
import { Paragraph } from '../paragraph/paragraph';

import { BulletListWrapper, Title, Ul } from './bullet-list.styled';

interface BulletListProps {
  bullets: BulletListFragment['bullets'];
  numeric?: boolean;
  title?: string;
}

export const BulletList: React.FC<BulletListProps> = ({
  bullets,
  numeric = false,
  title,
}) => {
  const parsedBullets
    = bullets?.split(/\r?\n/).filter((item) => !!item.trim()) || [];
  return (
    <BulletListWrapper>
      <ContentContainer>
        {title && <Title>{title}</Title>}
        <Ul as={numeric ? 'ol' : 'ul'}>
          {parsedBullets.map((item) => (
            <li>
              <Paragraph key={`key:${item}`}>{item}</Paragraph>
            </li>
          ))}
        </Ul>
      </ContentContainer>
    </BulletListWrapper>
  );
};
