import Link from 'next/link';

import { RouterLinkStyled } from './router-link.styled';

export interface RouterLinkProps {
  children?: React.ReactNode;
  to: string;
  id?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  dataValue?: string;
  className?: string;
}

export const RouterLink: React.FC<RouterLinkProps> = ({
  dataValue,
  to,
  children,
  className,
  id,
  onClick,
}) => {
  const extraProps = to?.startsWith('http')
    ? { rel: 'noreferrer noopener' }
    : {};
  return (
    <RouterLinkStyled className={className} data-value={dataValue}>
      <Link href={to || ''} onClick={onClick} id={id} {...extraProps}>
        {children}
      </Link>
    </RouterLinkStyled>
  );
};
