import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';

import { DynamicImageFragment } from '@hultafors/hultaforsgroup/types';

import { ImageWrapper } from './dynamic-feed-image.styled';

interface DynamicFeedImageProps {
  fullWidth?: boolean;
  image?: DynamicImageFragment | null;
}

export const DynamicFeedImage: React.FC<DynamicFeedImageProps> = ({
  image,
  fullWidth = false,
}) => {
  return image?.responsiveImage?.src
    ? (
      <ImageWrapper
        $fullWidth={fullWidth}
        $aspectRatio={image?.responsiveImage?.aspectRatio}
      >
        <Image
          src={image.responsiveImage.src}
          alt={image.alt || ''}
          blurDataURL={image.responsiveImage?.base64 || ''}
          placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
          fill
          sizes={
          fullWidth
            ? '100vw'
            : `calc(100vw - 48px), (min-width: ${breakpoints.mobileMax}) 62.8vw, (min-width: ${breakpoints.maxGridWidth}) 660px`
        }
        />
      </ImageWrapper>
      )
    : null;
};
