import { ContentContainer } from '../content-container/content-container';
import { HTagStyleType } from '../h-tag/h-tag.styled';

import { SmallHeroWrapper, Title, TopLabel } from './small-hero.styled';

interface SmallHeroProps {
  topLabel?: string;
  title?: string;
  styleType?: HTagStyleType;
  noMarginBottom?: boolean;
}

export const SmallHero: React.FC<SmallHeroProps> = ({
  topLabel,
  title,
  styleType = 'header1',
  noMarginBottom = false,
}) => {
  if (!title) {
    return null;
  }

  return (
    <SmallHeroWrapper $noMarginBottom={noMarginBottom} data-test="SmallHero">
      <ContentContainer>
        {topLabel && <TopLabel>{topLabel}</TopLabel>}
        <Title styleType={styleType}>{title}</Title>
      </ContentContainer>
    </SmallHeroWrapper>
  );
};
