import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const StyledSquareTextCTABlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  aspect-ratio: 9 / 16;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 16 / 9;
  }
`;
export const ImageMobile = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;
export const ImageTablet = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;
export const ImageDesktop = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`;
export const ImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${colors.HGGray2};
  z-index: -1;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: ${spacing.regular};
  background-color: ${colors.HGBlack};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: ${spacing.large};
  }
  ${contentContainerStyle};
`;

export const Header = styled(HTag).attrs({
  whiteText: true,
  type: 'h2',
  styleType: 'header2',
})`
  text-align: center;
  margin-bottom: ${spacing.xregular};
`;

export const Text = styled(Paragraph).attrs({ whiteText: true })`
  text-align: center;
  margin-bottom: ${spacing.xregular};
`;
