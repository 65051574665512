import { LinkProps } from 'next/link';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import { ButtonVariant, StyledButton, StyledButtonLink } from './button.styled';

interface ButtonProps {
  to?: LinkProps['href'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  variant?: ButtonVariant;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  loading,
  variant = 'black',
  to,
  onClick,
  children,
}) => {
  const { global } = useGlobal();
  if (to && onClick) {
    console.error("Can't have both onClick and to prop in Button component");
  }

  const extraProps
    = typeof to === 'string'
    && (to.startsWith('http:// ') || to.startsWith('https://'))
      ? { rel: 'noreferrer noopener', target: '_blank' }
      : {};

  return to
    ? (
      <StyledButtonLink $variant={variant} href={to} {...extraProps}>
        {children}
      </StyledButtonLink>
      )
    : (
      <StyledButton $variant={variant} onClick={onClick} disabled={loading}>
        {loading ? global.loading : children}
      </StyledButton>
      );
};
