import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

interface NavigationWrapperStyledProps {
  $disabled?: boolean;
  $isOpen?: boolean;
}

export const NavigationWrapperStyled = styled.div<NavigationWrapperStyledProps>`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${spacing.menuHeight};
  text-align: center;
  z-index: 10;
  background-color: ${colors.HGBlack};
  padding: 0 ${spacing.regular};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-left: ${spacing.medium};
    padding-right: ${spacing.medium};
  }
`;
