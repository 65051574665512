import Link from 'next/link';

import { routes } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';

import {
  ArticleItemWrapper,
  StyledDepartmentParagraph,
  StyledParagraph,
} from './article-item.styled';

interface ArticleItemProps {
  publishDate?: string;
  slug?: string;
  title?: string;
  category?: string;
  country?: string;
  department?: string;
  isNewsArticle?: boolean;
  isJobAd?: boolean;
}

export const ArticleItem: React.FC<ArticleItemProps> = ({
  publishDate,
  slug,
  title,
  category = '',
  country = '',
  department,
  isNewsArticle = false,
  isJobAd = false,
}) => {
  const createLink = () => {
    if (isNewsArticle) {
      return `/${routes.news}/${slug}`;
    }
    if (isJobAd) {
      return `/${routes.jobs}/${slug}`;
    }
    return `/${routes.news}/${slug}`;
  };
  return (
    <ArticleItemWrapper>
      <Link href={createLink()}>
        <StyledParagraph>
          {`${publishDate} - ${category}${
          country && ` - ${country}`
        }`}
        </StyledParagraph>
        <HTag type="h2" styleType="header4">
          {title}
        </HTag>
        {department && (
          <StyledDepartmentParagraph>{department}</StyledDepartmentParagraph>
        )}
      </Link>
    </ArticleItemWrapper>
  );
};
