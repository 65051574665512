import { createGlobalStyle, css } from 'styled-components';

import { fontWeights } from '@hultafors/hultaforsgroup/helpers';

const cssVariables = css`
  :root {
    --header-height: 56px;
    --map-offset: 0;
    --map-width: 100%;
    --map-height: 500px;
    --map-height-desktop: 600px;
  }
`;

const globalStyle = css`
  *,
  ::before,
  ::after {
    box-sizing: border-box; /* 1 */
    background-repeat: no-repeat; /* 2 */
  }
  html {
    font-size: 16px;
    font-weight: ${fontWeights.fontRegular};
    min-height: 100vh;
  }

  body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-weight: ${fontWeights.fontRegular};
  }

  #root {
    min-height: 100vh;
  }

  img {
    max-width: 100%;
  }

  .no-js img.lazyload {
    display: none;
  }

  .map {
    position: relative;
    width: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h6,
  p,
  a {
    padding: 0;
    margin: 0;
  }
  a {
    text-decoration: none;
    font-family: inherit;
    appearance: none;
    color: inherit;
  }
  button {
    font-family: inherit;
    appearance: none;
    border: none;
    background-color: transparent;
    outline: none;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${cssVariables};
  ${globalStyle};
`;
