import {
  HTagStyleTypeProp,
  HTagWhiteTextProp,
  StyledHTag,
} from './h-tag.styled';

export type HTagType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

interface HTagProps {
  className?: string;
  type?: HTagType;
  children?: React.ReactNode;
  whiteText?: HTagWhiteTextProp;
  center?: boolean;
  styleType?: HTagStyleTypeProp;
}

export const HTag: React.FC<HTagProps> = ({
  type = 'h1',
  className,
  whiteText = false,
  center = false,
  styleType = 'header1',
  children,
}) => {
  return (
    <StyledHTag
      as={type}
      className={className}
      $whiteText={whiteText}
      $center={center}
      $styleType={styleType}
    >
      {children}
    </StyledHTag>
  );
};
