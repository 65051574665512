import {
  BulletListFragment,
  DynamicContentImageFragment,
  EmployeeFragment,
  FullscreenImageCtaFragment,
  StatementBlockFragment,
  TextBlockFragment,
  TwoColumnImageAndTextFragment,
  TwoColumnTextIntroFragment,
  VideoFragment,
} from '../dato';

export type DynamicContentFields =
  | BulletListFragment
  | EmployeeFragment
  | FullscreenImageCtaFragment
  | DynamicContentImageFragment
  | StatementBlockFragment
  | TextBlockFragment
  | TwoColumnImageAndTextFragment
  | TwoColumnTextIntroFragment
  | VideoFragment;

export const DynamicContentTypeGuards = {
  isBulletList: (item: DynamicContentFields): item is BulletListFragment =>
    item.__typename === 'BulletListRecord',
  isEmployee: (item: DynamicContentFields): item is EmployeeFragment =>
    item.__typename === 'EmployeeRecord',
  isFullscreenImageCta: (
    item: DynamicContentFields,
  ): item is FullscreenImageCtaFragment =>
    item.__typename === 'FullscreenImageCtaRecord',
  isImage: (item: DynamicContentFields): item is DynamicContentImageFragment =>
    item.__typename === 'ImageRecord',
  isStatementBlock: (
    item: DynamicContentFields,
  ): item is StatementBlockFragment =>
    item.__typename === 'StatementBlockRecord',
  isTextBlock: (item: DynamicContentFields): item is TextBlockFragment =>
    item.__typename === 'TextBlockRecord',
  isTwoColumnImageAndText: (
    item: DynamicContentFields,
  ): item is TwoColumnImageAndTextFragment =>
    item.__typename === 'TwoColumnImageAndTextRecord',
  isTwoColumnTextIntro: (
    item: DynamicContentFields,
  ): item is TwoColumnTextIntroFragment =>
    item.__typename === 'TwoColumnTextIntroRecord',
  isVideo: (item: DynamicContentFields): item is VideoFragment =>
    item.__typename === 'VideoRecord',
};
