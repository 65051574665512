import Link from 'next/link';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const BrandsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const BrandLink = styled(Link)`
  > * + * {
    margin-block-start: ${spacing.xsmall};
  }
`;

export const OurBrandsBlockWrapper = styled.div`
  margin-inline: auto;
  width: 100%;
  max-width: ${breakpoints.maxGridWidth};
  padding-inline: ${spacing.regular};
  padding-block: ${spacing.medium} ${spacing.large};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding-block: ${spacing.large} ${spacing.XL};

    /* padding-inline: 0; */
  }
`;

export const Title = styled(HTag).attrs({
  type: 'h2',
  styleType: [
    { styleType: 'header1Mobile' },
    { breakpoint: 'mobileMax', styleType: 'header1' },
  ],
})`
  margin-bottom: ${spacing.regular};
`;

export const IntroText = styled(HTag).attrs({
  type: 'p',
  styleType: 'header3',
})`
  max-width: 60ch;
  margin-bottom: ${spacing.medium};
`;

export const BrandName = styled(Paragraph).attrs({ styleType: 'body14' })``;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
`;
