import { useId } from 'react';

import {
  DynamicContentFields,
  DynamicContentTypeGuards,
} from '@hultafors/hultaforsgroup/types';

import { BigTextBlock } from '../big-text-block/big-text-block';
import { BulletList } from '../bullet-list/bullet-list';
import { DynamicFeedImage } from '../dynamic-feed-image/dynamic-feed-image';
import { Employee } from '../employee/employee';
import { SquareTextCTABlock } from '../square-text-cta-block/square-text-cta-block';
import { TextBlock } from '../text-block/text-block';
import { TwoColumnTextImage } from '../two-column-text-image/two-column-text-image';
import { TwoColumnTextIntro } from '../two-column-text-intro/two-column-text-intro';
import { Video } from '../video/video';

import { StyledDynamicContent } from './dynamic-content.styled';

interface DynamicContentProps {
  content: DynamicContentFields[];
  padding?: React.CSSProperties['padding'];
}

const DynamicContentItem: React.FC<{ item: DynamicContentFields;even?: boolean }> = ({ item, even }) => {
  if (DynamicContentTypeGuards.isBulletList(item)) {
    return (
      <BulletList

        title={item.title || ''}
        bullets={item.bullets}
        numeric={item.numberedList}
      />
    );
  }
  if (DynamicContentTypeGuards.isEmployee(item)) {
    return (
      <Employee

        title={item.title || ''}
        introText={item.introText || ''}
        description={item.description || ''}
        name={item.name || ''}
        image={item.profileImage}
      />
    );
  }
  if (DynamicContentTypeGuards.isFullscreenImageCta(item)) {
    // Same as new HeroBlock
    return (
      <SquareTextCTABlock
        header={item.header || ''}
        text={item.text || ''}
        image={item.image}
        imageMobile={item.imageMobile}
        imageTablet={item.imageTablet}
        ctaText={item.ctaText || ''}
        ctaUrl={item.ctaUrl || ''}
      />
    );
  }
  if (DynamicContentTypeGuards.isImage(item)) {
    return (
      <DynamicFeedImage
        fullWidth={item.fullWidth}
        image={item.image}
      />
    );
  }
  if (DynamicContentTypeGuards.isStatementBlock(item)) {
    return (
      <BigTextBlock
        black={!!item.blackBackground}
        title={item.header || ''}
        description={item.text || ''}
        imageMobile={item.imageMobile}
        imageTablet={item.imageTablet}
        imageDesktop={item.imageDesktop}
        small={item.small}
        linkText={item.linkText || ''}
        linkUrl={item.linkUrl || ''}
      />
    );
  }
  if (DynamicContentTypeGuards.isTextBlock(item)) {
    return (
      <TextBlock
        smallTitle={item.smallHeader}
        title={item.header || ''}
        text={item.text || ''}
        introText={item.introText || ''}
      />
    );
  }
  if (DynamicContentTypeGuards.isTwoColumnImageAndText(item)) {
    return (
      <TwoColumnTextImage
        text={item.text || ''}
        title={item.title || ''}
        image={item.image}
        reverse={item.reverse || even}
      />
    );
  }
  if (DynamicContentTypeGuards.isTwoColumnTextIntro(item)) {
    return (
      <TwoColumnTextIntro
        header={item.header || ''}
        introText={item.introText || ''}
        text={item.text || ''}
      />
    );
  }
  if (DynamicContentTypeGuards.isVideo(item)) {
    return (
      <Video
        video={item.video}
        thumbnail={item.videoThumbnail?.responsiveImage?.src}
      />
    );
  }
  return null;
};

export const DynamicContent: React.FC<DynamicContentProps> = ({
  content = [],
  padding,
}) => {
  const uid = useId();

  if (!content.length) {
    return null;
  }

  const contentMapper = (item: DynamicContentFields, index: number) => {
    const key = `ContentArea-${uid}-${item.__typename}-${index}`;
    return <DynamicContentItem key={key} item={item} even={index % 2 === 0} />;
  };

  return (
    <StyledDynamicContent $padding={padding}>
      {content.map(contentMapper)}
    </StyledDynamicContent>
  );
};
