import Image from 'next/image';

import {
  HeroImageDesktopFragment,
  HeroImageMobileFragment,
  HeroImageTabletFragment,
} from '@hultafors/hultaforsgroup/types';

import {
  Description,
  HeroWrapper,
  ImageDesktop,
  ImageMobile,
  ImageTablet,
  ImageWrapper,
  Inner,
  Title,
} from './hero.styled';

interface HeroProps {
  title?: string;
  hideTitle?: boolean;
  description?: string;
  image?: HeroImageDesktopFragment;
  imageMobile?: HeroImageMobileFragment;
  imageTablet?: HeroImageTabletFragment;
  priority?: boolean;
}

export const Hero: React.FC<HeroProps> = ({
  title,
  hideTitle = false,
  description,
  image,
  imageMobile,
  imageTablet,
  priority = false,
}) => {
  if (!title && !image) {
    return null;
  }

  return (
    <HeroWrapper data-test="Hero">
      <ImageWrapper>
        {imageMobile?.responsiveImage?.src && (
          <ImageMobile>
            <Image
              src={imageMobile.responsiveImage.src}
              alt={imageMobile.alt || title || ''}
              blurDataURL={imageMobile.responsiveImage?.base64 || ''}
              placeholder={
                imageMobile.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes="100vw"
              priority={priority}
              fill
              style={{ objectFit: 'cover' }}
            />
          </ImageMobile>
        )}
        {imageTablet?.responsiveImage?.src && (
          <ImageTablet>
            <Image
              src={imageTablet.responsiveImage.src}
              alt={imageTablet.alt || title || ''}
              blurDataURL={imageTablet.responsiveImage?.base64 || ''}
              placeholder={
                imageTablet.responsiveImage?.base64 ? 'blur' : 'empty'
              }
              sizes="100vw"
              fill
              style={{ objectFit: 'cover' }}
            />
          </ImageTablet>
        )}
        {image?.responsiveImage?.src && (
          <ImageDesktop>
            <Image
              src={image.responsiveImage.src}
              alt={image.alt || title || ''}
              blurDataURL={image.responsiveImage?.base64 || ''}
              placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
              sizes="100vw"
              priority={priority && !imageMobile?.responsiveImage?.src}
              fill
              style={{ objectFit: 'cover' }}

            />
          </ImageDesktop>
        )}
      </ImageWrapper>
      <Inner>
        <Title
          $hideTitle={hideTitle}
          whiteText={!!image}
          styleType={[
            { styleType: 'header1MobileMedium' },
            { breakpoint: 'mobileMax', styleType: 'header1Medium' },
          ]}
        >
          {title}
        </Title>
        {description && (
          <Description whiteText={!!image}>{description}</Description>
        )}
      </Inner>
    </HeroWrapper>
  );
};
