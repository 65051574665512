import styled, { CSSProperties } from 'styled-components';

export const StyledGraphicWrapper = styled.div<{
  $width?: CSSProperties['width'];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => $width || 'inherit'};
`;

export const StyledGraphic = styled.img`
  display: inline-flex;
  width: 50%;
`;
