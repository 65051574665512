import { StyledImage } from './image.styled';

interface ImageProps {
  caption?: string;
  alt?: string;
  title?: string;
  className?: string;
  sizes?: string;
  src?: string;
  srcset?: string;
}

export const Image: React.FC<ImageProps> = ({
  caption,
  alt,
  title,
  className,
  src = '',
  srcset,
  sizes = 'auto',
}) => {
  return (
    <StyledImage
      alt={alt ?? caption}
      src={src}
      srcSet={srcset}
      sizes={sizes}
      title={title ?? caption}
      loading="lazy"
      className={className}
    />
  );
};
