import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import LogoSvg from '../../svg/logo.svg';
import TogglerMobileSvg from '../../svg/toggler-mobile.svg';
import { NavigationOpenState } from '../nav/nav';
import { SlideInToggler } from '../slide-in/slide-in';

import LanguageSvg from './language.svg';
import {
  LogoLink,
  MenuToggler,
  NavigationMobileStyled,
  TogglerButton,
} from './navigation-mobile.styled';

interface NavigationMobileProps {
  setIsOpen(value: NavigationOpenState): void;
  defaultOpenSettings: NavigationOpenState;
  toggleMenu: SlideInToggler;
  toggleMarketPicker(): void;
}

export const NavigationMobile: React.FC<NavigationMobileProps> = ({
  setIsOpen,
  defaultOpenSettings,
  toggleMenu,
  toggleMarketPicker,
}) => {
  const { global } = useGlobal();
  const { activeMarket } = useMarketPicker();
  const onClick = () => {
    toggleMenu();
  };

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return (
        <LanguageSvg width={24} height={24} color="white" />
      );
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }
  return (
    <NavigationMobileStyled data-test="NavigationMobile">
      <MenuToggler onClick={onClick} aria-label={global.menu}>
        <TogglerMobileSvg
          width={24}
          height={24}
          aria-hidden={true}
          focusable={false}
        />
      </MenuToggler>

      <LogoLink
        href="/"
        aria-label="Hultafors Group"
        onClick={() => setIsOpen({ ...defaultOpenSettings })}
      >
        <LogoSvg aria-hidden={true} focusable={false} height={14} />
      </LogoLink>

      <TogglerButton
        onClick={toggleMarketPicker}
        aria-label={global?.changeLanguage || ''}
      >
        {renderLanguageIcon()}
      </TogglerButton>
    </NavigationMobileStyled>
  );
};
