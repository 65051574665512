import { Button } from '../button/button';
import { ContentContainer } from '../content-container/content-container';

import {
  ButtonWrapper,
  ListPageWrapperWrapper,
  Title,
} from './list-page-news-wrapper.styled';

interface ListPageNewsWrapperProps {
  title?: string;
  children?: React.ReactNode;
  buttonText?: string;
  buttonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  showLoadMore?: boolean;
}

export const ListPageNewsWrapper: React.FC<ListPageNewsWrapperProps> = ({
  title,
  children,
  buttonText,
  buttonOnClick,
  loading,
  showLoadMore = false,
}) => (
  <ListPageWrapperWrapper>
    <ContentContainer wide>
      <Title>{title}</Title>
      {children}
      {showLoadMore && (
        <ButtonWrapper>
          <Button onClick={buttonOnClick} loading={loading}>
            {buttonText}
          </Button>
        </ButtonWrapper>
      )}
    </ContentContainer>
  </ListPageWrapperWrapper>
);
