import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

export const StyledNav = styled.nav`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: ${spacing.menuHeight};
  background: ${colors.gray1};
`;
