import {
  IconLinkRecord,
  ItemRecord,
  LinkRecord,
  MenuItemRecord,
} from '../dato';

export type LinkWithUrl = IconLinkRecord | LinkRecord | MenuItemRecord;
export type LinkField = LinkWithUrl | ItemRecord;

export const LinkFieldTypeGuards = {
  isIconLinkRecord: (item: LinkField): item is IconLinkRecord =>
    item.__typename === 'IconLinkRecord',
  isItemRecord: (item: LinkField): item is ItemRecord =>
    item.__typename === 'ItemRecord',
  isLinkRecord: (item: LinkField): item is LinkRecord =>
    item.__typename === 'LinkRecord',
  isMenuItemRecord: (item: LinkField): item is MenuItemRecord =>
    item.__typename === 'MenuItemRecord',
};
