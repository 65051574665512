import Link from 'next/link';
import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

const desktopMenuHeight = 700;

export const slideInTime = 300;

interface FromTopProps {
  $fromTop?: boolean;
}

interface ActiveProps {
  $active: boolean;
  $isOpen: boolean;
}

interface PadContentProps {
  $padContent?: boolean;
}

interface IsCartProps {
  $isCart?: boolean;
}

interface IsMenuProps {
  $isMenu?: boolean;
}

interface SmallProps {
  $small?: boolean;
}

interface SlideInStyledProps extends FromTopProps, PadContentProps, SmallProps {
  $fromLeft?: boolean;
}

export const SlideInBackground = styled.div<ActiveProps & FromTopProps>`
  position: fixed;
  background-color: ${colors.overlayTransparent};
  inset: 0;
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  opacity: ${({ $active, $isOpen }) => ($active && $isOpen ? 1 : 0)};
  transition: all ${slideInTime}ms;
  pointer-events: none;
  ${({ $fromTop }) =>
    $fromTop
    && css`
      top: 57px;
    `};
`;

export const SlideInContainer = styled.div<FromTopProps & ActiveProps>`
  pointer-events: none;
  visibility: ${({ $active }) => ($active ? 'visible' : 'hidden')};
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  z-index: 10;
  transition: opacity ${slideInTime}ms;

  ${({ $fromTop }) =>
    $fromTop
    && css`
      top: 57px;
      overflow-y: auto;
    `};

  ${({ $isOpen, $fromTop }) =>
    $isOpen
    && css`
      pointer-events: all;
      opacity: 1;
      transition: opacity ${slideInTime}ms;
    `};
`;

interface SlideInInsideProps extends ActiveProps, FromTopProps {
  $fromLeft?: boolean;
  $small?: boolean;
  $animating?: boolean;
}

export const SlideInInside = styled.aside<SlideInInsideProps>`
  position: absolute;
  top: 0;
  right: ${({ $fromLeft }) => ($fromLeft ? 'auto' : 0)};
  left: ${({ $fromLeft }) => ($fromLeft ? 0 : 'auto')};
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ $fromTop }) => ($fromTop ? 'auto' : '100%')};
  z-index: 12;
  box-sizing: border-box;
  background-color: ${colors.white};
  transition: transform ${slideInTime}ms ease-in-out,
    box-shadow ${slideInTime}ms ease-in-out;
  overflow: hidden;
  transform: ${({ $fromTop, $fromLeft }) =>
    $fromTop
      ? 'translateY(-100%)'
      : $fromLeft
      ? 'translateX(-100%)'
      : 'translateX(100%)'};
  ${({ $isOpen }) =>
    $isOpen
    && css`
      transform: translateY(0%) translateX(0%);
    `}
  ${({ $animating }) =>
    $animating
    && css`
      pointer-events: none;
    `}
  ${({ $fromTop }) =>
    $fromTop
    && css`
      overflow: visible;
    `}

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    width: 100%;
    max-width: ${({ $fromTop }) => ($fromTop ? 'none' : '400px')};
    max-height: ${({ $fromTop, $small }) =>
      $fromTop && !$small ? desktopMenuHeight + 'px' : 'none'};
    box-shadow: ${({ $isOpen }) =>
      $isOpen ? '0 0 20px rgba(0, 0, 0, 0.666)' : '0 0 0 rgba(0, 0, 0, 0)'};
  }
`;

export const SlideInBody = styled.div<
  PadContentProps & FromTopProps & IsCartProps
>`
  display: flex;
  flex-direction: column;
  padding: ${({ $padContent }) =>
    $padContent ? `20px 20px ${spacing.medium} 20px` : ''};
  overflow: ${({ $fromTop }) => ($fromTop ? 'visible' : 'auto')};
  height: ${({ $fromTop }) => ($fromTop ? 'auto' : '100%')};
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;

  ${({ $isCart }) =>
    $isCart
    && css`
      padding: 20px 20px 150px;
    `}

  ${({ $fromTop }) =>
    !$fromTop
    && css`
      overflow-x: hidden;
    `}
`;

export const SlideInHeader = styled.header<
  SmallProps & FromTopProps & IsMenuProps
>`
  display: flex;
  justify-content: 'space-between';
  align-items: center;
  height: ${({ $small }) => ($small ? '44px' : '56px')};
  min-height: ${({ $small }) => ($small ? '44px' : '56px')};
  padding-inline: 20px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: ${({ $fromTop }) => ($fromTop ? 'transparent' : colors.white)};
  border-bottom: ${({ $fromTop, $isMenu }) =>
    $fromTop || $isMenu ? 'none' : `thin solid ${colors.gray4}`};

  .BackButton {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 20px;
    height: 56px;
    border: none;
    background: none;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
    z-index: 1;
    cursor: pointer;

    img {
      transform: rotate(180deg);
      height: 14px;
      width: 10px;
      margin-inline-end: 8px;
    }
  }
`;

export const HeaderText = styled.div<IsMenuProps & FromTopProps>`
  display: flex;
  justify-content: ${({ $isMenu, $fromTop }) =>
    $isMenu && $fromTop ? 'flex-end' : 'space-between'};
  align-items: center;
  flex-grow: 1;
`;

export const HeaderTextInner = styled.span`
  font-size: 16px;
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.5px;

  @media screen and (min-width: ${breakpoints.bigMobile}) {
    font-size: 18px;
  }
`;

const headerIconStyle = css`
  width: 24px;
  height: 24px;
  display: inline-flex;
  margin-inline-end: ${spacing.xsmall};
  background: none;
  border: none;
  padding: 0;
  outline: none;
`;

export const HeaderIcon = styled.img`
  ${headerIconStyle};
`;

export const Close = styled.button<IsMenuProps>`
  ${headerIconStyle};

  cursor: pointer;
  ${({ $isMenu }) =>
    $isMenu
    && css`
      width: 44px;
      height: 44px;
      margin-inline-end: 12px;
      margin-left: -16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: ${breakpoints.bigMobile}) {
        margin-inline-end: 20px;
      }
    `}
`;

export const Clear = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  padding: 0;
`;

export const ClearLabel = styled.span`
  height: 13px;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 20px;
  font-weight: bold;
  width: 48px;
  height: 48px;
  margin-inline-end: -16px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  svg {
    width: 16px;
    height: 16px;
  }

  svg path {
    transition: 0.2s ease;
  }

  &:hover {
    svg path {
      fill: ${colors.gray1};
    }
  }
`;

export const ExternalLinksContainer = styled.div`
  position: absolute;
  right: ${spacing.small};
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;

  div {
    display: flex;
    height: 100%;
    padding: ${spacing.small};
    border-left: thin solid ${colors.gray4};
  }

  div:last-child {
    padding-inline-end: 0;
  }
`;

export const ExternalLink = styled(Link)`
  color: ${colors.black};
  display: flex;
  height: 48px;
  align-items: center;
  letter-spacing: 0.15rem;
  text-decoration: none;
  text-transform: uppercase;
  font-size: ${fontSizes.body12};
  font-weight: ${fontWeights.fontMedium};

  svg {
    height: 1rem;
    margin-left: ${spacing.xsmall};
  }
`;

export const SlideInExit = styled.div`
  display: flex;
  align-items: center;

  &:empty {
    display: none;
  }
`;
