import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const contentContainerStyle = css`
  margin-inline: auto;
  width: calc(100vw - 48px);

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    width: 62.8vw;
    max-width: 660px;
  }
`;

export const contentContainerWideStyle = css`
  margin-inline: auto;
  width: calc(100vw - 48px);
  max-width: 1000px;
`;

interface ContentContainerStyledProps {
  $wide?: boolean;
}

export const ContentContainerStyled = styled.div<ContentContainerStyledProps>`
  ${({ $wide }) => ($wide ? contentContainerWideStyle : contentContainerStyle)}
`;
