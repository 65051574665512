import {
  Header,
  IntroText,
  MarkdownWrapper,
  StyledMarkdown,
  TwoColumnTextIntroWrapper,
} from './two-column-text-intro.styled';

interface TwoColumnTextIntroProps {
  header?: string;
  introText?: string;
  text?: string;
}

export const TwoColumnTextIntro: React.FC<TwoColumnTextIntroProps> = ({
  header,
  introText,
  text,
}) => {
  return (
    <TwoColumnTextIntroWrapper>
      <div>
        <Header>{header}</Header>
      </div>
      <div>
        <IntroText>{introText}</IntroText>
        <MarkdownWrapper>
          <StyledMarkdown>{text ?? ''}</StyledMarkdown>
        </MarkdownWrapper>
      </div>
    </TwoColumnTextIntroWrapper>
  );
};
