import styled, { css } from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';

export const HeroWrapper = styled.div`
  display: flex;
  align-items: center;
  inline-size: 100%;
  position: relative;
  padding: ${spacing.regular};
  max-block-size: 65vh;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0 ${spacing.regular};
  }

  aspect-ratio: 3 / 5;

  @media screen and (min-width: ${breakpoints.tablet}) {
    aspect-ratio: 1 / 1;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    aspect-ratio: 2 / 1;
    max-block-size: 70vh;
  }
`;

export const ImageMobile = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const ImageTablet = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: none;
  }
`;

export const ImageDesktop = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.desktop}) {
    display: block;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${colors.HGGray3};
`;

export const Inner = styled.div`
  margin-inline: auto;
  width: calc(100vw - 48px);
  max-width: 830px;
  z-index: 1;
`;

interface HeroTitleProps {
  $hideTitle?: boolean;
}
export const Title = styled(HTag).attrs({
  type: 'h1',
})<HeroTitleProps>`
  max-width: 660px;
  ${({ $hideTitle }) =>
    $hideTitle
    && css`
      &:not(:focus, :active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      }
    `}
  ${({ whiteText }) =>
    whiteText
    && css`
      text-shadow: 0 0 0.25ch ${colors.textShadowBlack};
    `}
`;

export const Description = styled(HTag).attrs({
  type: 'p',
  styleType: 'header4',
})`
  max-width: 660px;
  margin-block-start: ${spacing.medium};
  ${({ whiteText }) =>
    whiteText
    && css`
      text-shadow: 0 0 0.25ch ${colors.textShadowBlack};
    `}
`;
