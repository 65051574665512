import Image from 'next/image';

import { breakpoints } from '@hultafors/shared/constants';
import { ImageFragment } from '@hultafors/shared/types';

import {
  Description,
  EmployeeWrapper,
  ImageWrapper,
  IntroHeader,
  Left,
  Name,
  Right,
  Title, TitleNameWrapper,
} from './employee.styled';

interface EmployeeProps {
  title?: string;
  introText?: string;
  description?: string;
  name?: string;
  image?: ImageFragment | null;
}

export const Employee: React.FC<EmployeeProps> = ({
  title,
  introText,
  description,
  name,
  image,
}) => (
  <EmployeeWrapper>
    {image?.responsiveImage?.src && (
      <Left>
        <ImageWrapper>
          <Image
            src={image.responsiveImage.src}
            alt={image.alt || name || ''}
            blurDataURL={image.responsiveImage?.base64 || ''}
            placeholder={image.responsiveImage?.base64 ? 'blur' : 'empty'}
            sizes={`100vw, (min-width: ${breakpoints.mediumMobileMax}) 375px, (min-width: ${breakpoints.mobileMax}) 160px`}
            fill
          />
        </ImageWrapper>
      </Left>
    )}
    <Right>
      {title && name && (
        <TitleNameWrapper>
          <Name>{name}</Name>
          <Title>{title}</Title>
        </TitleNameWrapper>
      )}
      {introText && <IntroHeader>{introText}</IntroHeader>}
      {description && (
        <Description>
          {description}
        </Description>
      )}
    </Right>
  </EmployeeWrapper>
);
