import styled from 'styled-components';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';
import { Markdown } from '../markdown/markdown';

export const TextBlockWrapper = styled.div`
  ${contentContainerStyle};
  margin-block: ${spacing.medium};
`;

export const Title = styled(HTag).attrs({ type: 'h2' })`
  margin-bottom: ${spacing.small};
`;

export const IntroText = styled(HTag).attrs({
  type: 'h3',
  styleType: 'header3',
})`
  margin-bottom: ${spacing.small};
`;

export const StyledMarkdown = styled(Markdown)`
  white-space: pre-wrap;

  p:not(:last-child) {
    padding-bottom: ${spacing.regular};
  }

  a {
    text-decoration: underline;
    color: ${colors.HGBlack};
    padding-inline: ${spacing.regular};
  }
`;
