import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/hultaforsgroup/helpers';

export type ParagraphStyleType = 'body16' | 'body14' | 'label';

interface StyledParagraphProps {
  $whiteText?: boolean;
  $styleType?: ParagraphStyleType;
}

const fontStyle = (styleType?: ParagraphStyleType) => {
  if (styleType === 'body14') {
    return css`
      font-size: ${fontSizes.body14};
      line-height: ${lineHeights.body14};
      font-weight: ${fontWeights.fontRegular};
    `;
  }
  if (styleType === 'label') {
    return css`
      font-size: ${fontSizes.label};
      line-height: ${lineHeights.label};
      font-weight: ${fontWeights.fontMedium};
      letter-spacing: 0.1em;
      text-transform: uppercase;
    `;
  }
  return css`
    font-size: ${fontSizes.body16};
    line-height: ${lineHeights.body16};
    font-weight: ${fontWeights.fontRegular};
  `;
};

export const StyledParagraph = styled.p<StyledParagraphProps>`
  color: ${({ $whiteText }) => ($whiteText ? colors.HGWhite : colors.HGBlack)};
  ${({ $styleType }) => fontStyle($styleType)}
`;
