import styled from 'styled-components';

import { colors } from '@hultafors/hultaforsgroup/helpers';

import { Paragraph } from '../paragraph/paragraph';

export const ArticleItemWrapper = styled.article`
  padding: 32px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.gray4};
  }

  &:only-child {
    border-bottom: 1px solid ${colors.gray4};
  }
`;

export const StyledParagraph = styled(Paragraph).attrs({ styleType: 'label' })`
  margin-bottom: 8px;
`;

export const StyledDepartmentParagraph = styled(Paragraph).attrs({
  styleType: 'label',
})`
  margin-top: 8px;
`;
