import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import {
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export const StyledInput = styled.input`
  appearance: none;
  flex: 1;
  width: 100%;
  padding: ${spacing.xsmall};
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  border-bottom: 1px solid white;
  background-color: transparent;
  font-size: ${fontSizes.body16};
  line-height: ${lineHeights.body16};
  font-weight: ${fontWeights.fontRegular};

  &:focus {
    outline: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding: ${spacing.small};
  }
`;
