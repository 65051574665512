import {
  StyledInput,
  StyledSliderSpan,
  StyledToggleSwitchLabel,
} from './toggle-switch.styled';

interface ToggleSwitchProps {
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked = false,
  onChange,
}) => (
  <StyledToggleSwitchLabel>
    <StyledInput type="checkbox" checked={checked} onChange={onChange} />
    <StyledSliderSpan className="slider" />
  </StyledToggleSwitchLabel>
);
