import { HTagStyleTypeProp } from '../h-tag/h-tag.styled';
import { Paragraph } from '../paragraph/paragraph';

import {
  IntroText,
  StyledMarkdown,
  TextBlockWrapper,
  Title,
} from './text-block.styled';

interface TextBlockProps {
  smallTitle?: boolean;
  title?: string;
  text?: string;
  introText?: string;
}

export const TextBlock: React.FC<TextBlockProps> = ({
  smallTitle = false,
  title,
  text,
  introText,
}) => {
  let headerStyleType: HTagStyleTypeProp = 'header2Medium';
  if (smallTitle) {
    headerStyleType = 'header4Medium';
  }

  return (
    <TextBlockWrapper>
      {title && <Title styleType={headerStyleType}>{title}</Title>}
      {introText && <IntroText>{introText}</IntroText>}
      {text && (
        <Paragraph styleType="body16" as="div">
          <StyledMarkdown options={{ forceBlock: true }}>{text}</StyledMarkdown>
        </Paragraph>
      )}
    </TextBlockWrapper>
  );
};
