import {
  StyledContactInformationBlock,
  Title,
} from './contact-information-block.styled';

interface ContactInformationBlockProps {
  children?: React.ReactNode;
  title?: string;
}

export const ContactInformationBlock: React.FC<
  ContactInformationBlockProps
> = ({ children, title }) => {
  return (
    <StyledContactInformationBlock>
      {title && <Title>{title}</Title>}
      {children}
    </StyledContactInformationBlock>
  );
};
