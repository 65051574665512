import Link from 'next/link';
import styled, { css } from 'styled-components';

import {
  colors,
  fontSizes,
  fontWeights,
  lineHeights,
  spacing,
} from '@hultafors/hultaforsgroup/helpers';

export type ButtonVariant = 'black' | 'white';

interface StyledButtonProps {
  $variant: ButtonVariant;
  $loading?: boolean;
}

const buttonStyle = css<StyledButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${spacing.medium};
  min-width: 147px;
  width: fit-content;
  background-color: ${({ $variant = 'black' }) =>
    $variant === 'black' ? colors.HGBlack : colors.HGWhite};
  color: ${({ $variant = 'black' }) =>
    $variant === 'black' ? colors.HGWhite : colors.HGBlack};
  text-decoration: none;
  padding: 17px 40px;
  cursor: pointer;
  border: 1px solid
    ${({ $variant = 'black' }) =>
      $variant === 'black' ? colors.HGBlack : colors.HGWhite};
  transition: 0.2s;
  outline: none;
  font-size: ${fontSizes.label};
  line-height: ${lineHeights.label};
  font-weight: ${fontWeights.fontMedium};
  letter-spacing: 0.1em;
  text-transform: uppercase;

  :hover {
    background-color: ${({ $variant }) =>
      $variant === 'black' ? colors.HGWhite : colors.HGBlack};
    color: ${({ $variant }) =>
      $variant === 'black' ? colors.HGBlack : colors.HGWhite};
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  ${buttonStyle};
`;
export const StyledButtonLink = styled(Link)<StyledButtonProps>`
  ${buttonStyle};
`;
