import { LogoFragment } from '@hultafors/hultaforsgroup/types';

import { ContentContainer } from '../content-container/content-container';
import { Markdown } from '../markdown/markdown';
import { TextButton } from '../text-button/text-button';

import { BrandPageBlockWrapper, StyledImage } from './brand-page-block.styled';

interface BrandPageBlockProps {
  text?: string;
  title?: string;
  linkUrl?: string;
  linkText?: string;
  logo: LogoFragment;
}

export const BrandPageBlock: React.FC<BrandPageBlockProps> = ({
  text,
  linkUrl,
  linkText,
  logo,
  title,
}) => {
  const style: React.CSSProperties = { objectFit: 'contain' };

  const height = 100;
  const width = logo?.responsiveImage?.aspectRatio
    ? Math.round(logo?.responsiveImage?.aspectRatio * 100)
    : 450;
  return (
    <BrandPageBlockWrapper data-test="BrandPageBlock">
      <ContentContainer>
        {logo?.responsiveImage?.src && (
          <StyledImage
            src={logo.responsiveImage.src}
            alt={logo.alt || title || ''}
            width={width}
            height={height}
            style={style}
            blurDataURL={logo.responsiveImage?.base64 || ''}
            placeholder={logo.responsiveImage?.base64 ? 'blur' : 'empty'}
          />
        )}

        <Markdown>{text}</Markdown>
        {linkUrl && (
          <TextButton arrow to={linkUrl}>
            {linkText}
          </TextButton>
        )}
      </ContentContainer>
    </BrandPageBlockWrapper>
  );
};
