import styled from 'styled-components';

export const StyledContentWrapper = styled.div`
  width: 100%;
  min-height: 70vh;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  @media (width >= 1440px) {
    padding: 0;
  }
`;
