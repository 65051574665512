import { SectionStyled } from './section.styled';

interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

export const Section: React.FC<SectionProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <SectionStyled className={className} style={style}>
      {children}
    </SectionStyled>
  );
};
