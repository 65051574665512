import { ParagraphStyleType, StyledParagraph } from './paragraph.styled';

export interface ParagraphProps {
  className?: string;
  children?: React.ReactNode;
  as?: string | React.ComponentType<any>;
  style?: React.CSSProperties;
  whiteText?: boolean;
  styleType?: ParagraphStyleType;
}

export const Paragraph: React.FC<ParagraphProps> = ({
  className,
  as,
  children,
  styleType,
  whiteText,
  style,
}) => {
  return (
    <StyledParagraph
      className={className}
      style={style}
      as={as ?? 'p'}
      $styleType={styleType}
      $whiteText={whiteText}
    >
      {children}
    </StyledParagraph>
  );
};
