import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerWideStyle } from '../content-container/content-container.styled';
import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

export const TwoColumnTextIntroWrapper = styled.div`
  ${contentContainerWideStyle};
  display: grid;
  gap: ${spacing.regular};
  border-bottom: 1px solid ${colors.gray4};
  margin-block-start: ${spacing.medium};
  padding-block-end: ${spacing.large};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    grid-template-columns: repeat(2, 1fr);
    margin-block-start: ${spacing.large};
    padding-block-end: ${spacing.large};
  }
`;

export const Header = styled(HTag).attrs({
  type: 'h2',
  styleType: 'header2Medium',
})``;

export const IntroText = styled(HTag).attrs({
  type: 'p',
  styleType: 'header3',
})`
  margin-bottom: ${spacing.regular};
`;

export const MarkdownWrapper = styled(Paragraph).attrs({
  styleType: 'body16',
  as: 'div',
})``;

export const StyledMarkdown = styled(Markdown).attrs({
  options: { forceBlock: true },
})`
  p:not(:last-child) {
    padding-bottom: ${spacing.regular};
  }

  a {
    text-decoration: underline;
    color: ${colors.HGBlack};
  }
`;
