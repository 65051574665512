import { Fragment } from 'react';

import Markdown from 'markdown-to-jsx';
import Image from 'next/image';
import Link from 'next/link';

import { useGlobal } from '@hultafors/hultaforsgroup/hooks';

import LogoSvg from '../../svg/logo.svg';
import PartnerSvg from '../../svg/partner.svg';
import { RouterLink } from '../router-link/router-link';
import { Section } from '../section/section';
import { TextButton } from '../text-button/text-button';

import {
  BottomSection,
  Content,
  FooterStyled,
  LogoLink,
  MiddleSection,
  SocialMedia,
  StartActions,
  StartSection,
} from './footer.styled';

export const Footer: React.FC = () => {
  const { footer, global } = useGlobal();

  const openDialog = () => {
    // TODO fix TS error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  return (
    <FooterStyled data-test="Footer">
      <Section>
        <StartSection>
          <LogoLink href="/" aria-label="Hultafors Group">
            <LogoSvg aria-hidden={true} focusable={false} />
          </LogoLink>
          <StartActions>
            {/* <LanguageSelector
              chooseLanguageLabel={global?.changeLanguage || ''}
              dark
            /> */}
            {global.partnerPortalUrl && (
              <TextButton
                to={global.partnerPortalUrl}
                variant="white"
                icon={(
                  <PartnerSvg
                    width={24}
                    height={24}
                    aria-hidden={true}
                    focusable={false}
                  />
                )}
              >
                {footer.partnerLogin}
              </TextButton>
            )}
          </StartActions>
        </StartSection>

        <MiddleSection>
          <Content>
            <div className="IntroText">
              {footer.hultaforsBrands.length > 0 && (
                <Markdown
                  options={{ forceBlock: true }}
                  className="markdown-wrapper"
                >
                  {footer.introText || ''}
                </Markdown>
              )}
              {footer.hultaforsBrands.map((item, index) => {
                return (
                  <Fragment key={`Company-${index}`}>
                    <RouterLink
                      to={item.url || ''}
                      className="HultaforsBrandLink"
                    >
                      <p className="BrandLink">{item.title}</p>
                    </RouterLink>
                    {index < footer.hultaforsBrands.length - 1 && <>|</>}
                  </Fragment>
                );
              })}
            </div>
            <Markdown
              options={{ forceBlock: true }}
              className="markdown-wrapper"
            >
              {footer.moreInfoText || ''}
            </Markdown>
          </Content>
        </MiddleSection>
        {footer.menu && (
          <BottomSection>
            <div className="BottomSectionLinks">
              {footer.menu.map((item, index) => {
                return (
                  <RouterLink to={item.url || ''} key={`Company-${index}`}>
                    {item.title}
                    {' '}
                    {index < footer.menu.length - 1 && <>{'\u00A0|\u00A0'}</>}
                  </RouterLink>
                );
              })}
              {'\u00A0|\u00A0'}

              <button
                className="CookieSettingsBtn"
                onClick={(e) => {
                  e.preventDefault();
                  openDialog();
                }}
              >
                {footer.cookieSettings}
              </button>
            </div>
            <SocialMedia>
              {footer.socialMediaMenu.map((item, index) => {
                return item.url
                  ? (
                    <Link
                      key={`SocialMedia-${index}`}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label={item.icon?.title || ''}
                    >
                      <Image
                        src={item.icon?.url || ''}
                        alt={item.icon?.alt || 'Social Media'}
                        width={32}
                        height={32}
                      />
                    </Link>
                    )
                  : null;
              })}
            </SocialMedia>
          </BottomSection>
        )}
      </Section>
    </FooterStyled>
  );
};
