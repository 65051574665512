import {
  LinkField,
  LinkFieldTypeGuards,
} from '@hultafors/hultaforsgroup/types';

import { createLink } from './create-link';
import { routes } from './routes';

export const createSubMenuLink = (item: LinkField, parent?: LinkField) => {
  if (item) {
    const { isItemRecord } = LinkFieldTypeGuards;

    if (isItemRecord(item)) {
      switch (item?.pageLink?.__typename) {
        case 'BrandPageRecord':
          return createLink(routes.brands, item, parent);
        case 'SustainabilityPageRecord':
          return createLink(routes.sustainability, item, parent);
        case 'AboutUsPageRecord':
          return createLink(routes.aboutUs, item, parent);
        default:
          return '';
      }
    } else {
      return item.url || '';
    }
  }
  return '';
};
