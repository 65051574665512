import styled, { css, CSSProperties } from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { contentContainerStyle } from '../content-container/content-container.styled';

export const ImageWrapper = styled.div<{
  $fullWidth?: boolean;
  $aspectRatio?: CSSProperties['aspectRatio'];
}>`
  position: relative;
  aspect-ratio: ${({ $aspectRatio }) =>
    $aspectRatio ? $aspectRatio : '16 / 10'};
  margin-block: ${spacing.medium};
  ${({ $fullWidth }) =>
    !$fullWidth
    && css`
      ${contentContainerStyle};
    `};
`;
