import styled from 'styled-components';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

export const StyledPicture = styled.picture`
  display: block;
  width: 100%;
  margin: ${spacing.medium} auto;

  img {
    display: block;
  }
`;
