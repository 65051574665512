import { NavigationWrapperStyled } from './navigation-wrapper.styled';

interface NavigationWrapperProps {
  children?: React.ReactNode;
  disabled?: boolean;
  isOpen?: boolean;
}

export const NavigationWrapper: React.FC<NavigationWrapperProps> = ({
  children,
  disabled = false,
  isOpen = false,
}) => {
  return (
    <NavigationWrapperStyled $disabled={disabled} $isOpen={isOpen}>
      {children}
    </NavigationWrapperStyled>
  );
};
