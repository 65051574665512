import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';
import { Paragraph } from '../paragraph/paragraph';

interface SmallHeroWrapperProps {
  $noMarginBottom?: boolean;
}

export const SmallHeroWrapper = styled.div<SmallHeroWrapperProps>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 ${spacing.regular};
  margin-top: ${spacing.medium};
  margin-bottom: ${({ $noMarginBottom }) =>
    $noMarginBottom ? 0 : spacing.medium};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const TopLabel = styled(Paragraph).attrs({ styleType: 'label' })`
  margin-bottom: ${spacing.xsmall};
`;

export const Title = styled(HTag).attrs({ type: 'h1' })``;
