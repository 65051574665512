import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { spacing } from '@hultafors/hultaforsgroup/helpers';

import { HTag } from '../h-tag/h-tag';

export const ListPageWrapperWrapper = styled.div`
  padding: 0 ${spacing.regular};
  margin-top: ${spacing.large};
  margin-bottom: ${spacing.XL};

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    padding: 0;
  }
`;

export const Title = styled(HTag).attrs({ type: 'h2', styleType: 'header2' })`
  padding-bottom: ${spacing.xsmall};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: ${spacing.xsmall};
`;
